export const BIJWERKINGENTOOL_URL = `//${HOST_URL}/static/bijwerkingentool/`;

export default class Bijwerkingentool {
    constructor(targetSelector) {
        this.target = document.querySelector(targetSelector);

        if (this.target === null) {
            throw new Error(`Element ${targetSelector} not found`);
        }

        this.template = {
            htmlTemplate: null,
            button: null,
            main: null,
            data: null,
            currentQuestion: 0,
            salutation: 'u',
        };
        this.answers = [];
        this.questionData = null;
        this.data_sent = false;
        this.filledInForms = [];

        this.allData = {};

        this.init();
    }

    /**
     * Fetch the default required data.
     */
    init() {
        fetch(`${BIJWERKINGENTOOL_URL}questions.json`, {}).then((response) => response.json()).then((questionData) => {
            this.questionData = questionData;
            fetch(`${BIJWERKINGENTOOL_URL}bijwerkingentool.html`, {}).then((response) => response.text()).then((template) => this.populateTemplate(template));
        });

        const link = document.createElement('link');
        link.href = `//${HOST_URL}/static/bijwerkingentool/css/bijwerkingentool.css`;
        link.rel = 'stylesheet';
        link.type = 'text/css';
        document.head.appendChild(link);
    }

    /**
     * Send the data of the questions that have been answered to the server.
     */
    sendDataToServer() {
        fetch(`//${HOST_URL}/api/bijwerkingentool/submit`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                questions: this.questionData,
                answers: this.answers,
                allData: this.allData,
            }),
        })
            .then((response) => response.json()).then(() => {
                this.data_sent = true;
            });
    }

    /**
     * Add the template of the bijwerkingentool.html to the javascript,
     * this is used to render the template later on.
     */
    populateTemplate(template) {
        this.template.htmlTemplate = document.createElement('div');
        this.template.htmlTemplate.innerHTML = template;
        this.target.innerHTML = this.template.htmlTemplate.innerHTML;

        this.target.classList.add('bijwerkingentool');

        this.template.modal = this.target.querySelector('.rse-modal-background');
        this.template.button = document.querySelector('.js--meld-bijwerking-button');

        // Render first question.
        this.renderQuestion(0);

        this.registerBaseButtonEvent();
    }

    /**
     * Register the close button events to the buttons.
     */
    registerBaseButtonEvent() {
        this.template.modal.querySelector('.rse-modal-close').replaceWith(this.template.modal.querySelector('.rse-modal-close').cloneNode(true));

        this.template.button.addEventListener(
            'click',
            () => this.template.modal.classList.remove('rse-modal-background--hidden'),
        );

        this.template.modal.querySelector('.rse-modal-close').addEventListener(
            'click',
            (e) => {
                e.preventDefault();
                this.displayCloseConfirmation();
            },
        );

    // this.registerQuestionButtons();
    }

    reset() {
        this.template.modal.querySelector('.rse-modal-question-bulletins').innerHTML = '';
        this.template.modal.querySelector('.rse-modal-form-container').innerHTML = '';
        this.template.modal.querySelector('.rse-modal-footer').innerHTML = '';
        this.answers = [];
        this.filledInForms = [];

        // Render first question.
        this.renderQuestion(0);

        this.registerBaseButtonEvent();
    }

    /**
     * Register the previous/next button events to the buttons.
     */
    registerQuestionButtons() {
    /* eslint-disable-next-line max-len */
        const currentQuestion = this.questionData.find((obj) => obj.id === this.template.currentQuestion);
        const self = this;
        if (this.template.modal.querySelector('.js--next-question') !== null) {
            this.template.modal.querySelector('.js--template-bijwerkingentool-form').addEventListener(
                'submit',
                () => {
                    this.renderQuestion(currentQuestion ? currentQuestion.next_button_id : 1);
                },
            );

            this.template.modal.querySelector('.js--next-question').addEventListener(
                'click',
                () => {
                    self.template.modal.querySelector('.js--form-submit-button').click();

                    this.filledInForms.push(this.template.currentQuestion - 1);
                    // if(answered === false && form.elements.length > 0)
                    //     alert('Please select one of the following answers');
                },
            );
        }

        if (this.template.modal.querySelector('.js--previous-question') !== null
            && !this.data_sent) {
            this.template.modal.querySelector('.js--previous-question').addEventListener(
                'click',
                () => this.renderQuestion(currentQuestion ? currentQuestion.back_button_id : 1),
            );
        }
    }

    /**
     * Render The Questions to the main part of the template.
     */
    renderQuestion(id) {
        this.saveFormData();

        if (id === -1) {
            this.template.modal.classList.remove('rse-modal-background--hidden');
            return;
        }

        const questionsData = this.questionData.find((obj) => obj.id === id);
        const self = this;
        this.template.currentQuestion = id;

        let text = this.template.htmlTemplate.querySelector('.js--template-bijwerkingentool-question-form').innerHTML;

        Object.keys(questionsData).forEach((key) => {
            /* This is to replace the different salutations for the texts,
            ** adding a variable-salutation in the json will replace the variable,
            */

            /* e.g. when you have {{title}} in the template and title-u in the json,
            ** the title-u will be used if the template.salutation equals everything after the '-'.
            */
            if (key.includes(self.template.salutation)) {
                text = text.replace(`{{${key.replace(`-${self.template.salutation}`, '')}}}`, questionsData[key]);
            }
            else {
                text = text.replace(`{{${key}}}`, questionsData[key]);
            }
        });

        const elem = document.createElement('div');

        elem.innerHTML = text;

        questionsData.questions.forEach((question) => {
            question.answers.forEach((answer) => {
                elem.querySelector('.js--template-bijwerkingentool-form').appendChild(this.renderInput(question, answer));
            });
        });

        this.template.modal.querySelector('.rse-modal-form-container').replaceChildren(elem);

        this.renderQuestionBulletins();

        if (questionsData.footer !== null && questionsData.footer !== undefined) {
            this.renderFooterButtons(questionsData.footer);
        }

        this.registerQuestionButtons();

        if (this.filledInForms.includes(this.template.currentQuestion)) {
            this.removeRequiredAttributes();

            document.querySelector('.js--next-question').disabled = false;
        }
    }

    /**
     * Render the buttons to the footer of the file.
     */
    renderFooterButtons(selector) {
        const footer = this.template.htmlTemplate.querySelector(selector);

        if (footer === null) {
            throw new Error(`Footer ${selector} not found`);
        }

        this.template.modal.querySelector('.rse-modal-footer').innerHTML = footer.innerHTML;

        if (this.template.modal.querySelector('.submit-data') !== null) {
            this.template.modal.querySelector('.submit-data').addEventListener('click', () => this.sendDataToServer());
        }

        let answerValue = null;
        this.answers.forEach((obj) => {
            Object.keys(obj).forEach((prop) => {
                if (`[${this.template.currentQuestion}][${1}]` === prop) {
                    answerValue = obj[prop];
                }
            });
        });

        if (answerValue !== null) {
            document.querySelector('.js--next-question').disabled = false;
        }
        else {
            document.querySelector('.js--next-question').disabled = this.doesFormHaveRequiredFields();
        }
    }

    displayCloseConfirmation() {
        const html = this.template.htmlTemplate.querySelector('.js--template-close-confirmation').innerHTML;

        if (!this.data_sent && this.answers.length > 0) {
            const elem = document.createElement('div');
            elem.innerHTML = html;

            // Add click events to remove screen and delete form
            elem.querySelector('.js--ignore').addEventListener('click', (e) => {
                e.preventDefault();
                this.template.modal.querySelector('.js--template-close-confirmation').remove();
            });

            elem.querySelector('.js--stop').addEventListener('click', (e) => {
                e.preventDefault();
                this.template.modal.querySelector('.js--template-close-confirmation').remove();
                this.template.modal.classList.add('rse-modal-background--hidden');
                this.reset();
            });

            this.template.modal.appendChild(elem.firstElementChild);
        }
        else {
            this.template.modal.classList.add('rse-modal-background--hidden');
            this.reset();
        }
    }

    /**
     * Save the form data to the this.answers variable.
     */
    saveFormData() {
        const form = this.template.modal.querySelector('form');

        if (form === null) {
            return;
        }

        const data = new FormData(form);
        const jsonData = {};
        const answerData = {};

        data.forEach((value, key) => {
            if (value) {
                const splitted = key.split('][');

                for (let i = 0; i < splitted.length; i++) {
                    splitted[i] = splitted[i].replace('[', '');
                    splitted[i] = splitted[i].replace(']', '');
                }

                const currentQuestion = this.questionData[this.template.currentQuestion];
                const questionType = currentQuestion.questions[0].type;

                if (questionType === 'mcq' || questionType === 'checkbox') {
                    const {description} = currentQuestion;
                    let title = '';

                    try {
                        /* eslint-disable eqeqeq */
                        title = currentQuestion.questions[0].answers
                            .find((x) => x.id == value)
                            .title;
                        /* eslint-enable eqeqeq */
                    }
                    catch {
                        title = value;
                    }

                    answerData[description] = title;
                }
                else {
                    /* eslint-disable eqeqeq */
                    const {title} = currentQuestion.questions[0].answers.find(
                        (x) => x.id == splitted[2],
                    );
                    /* eslint-enable eqeqeq */
                    const answer = value;

                    answerData[title] = answer;
                }
            }
        });

        /* eslint-disable-next-line max-len */
        const questionData = this.questionData.find((obj) => obj.id === this.template.currentQuestion);
        /* eslint-enable-next-line max-len */

        // The index of the json_data.
        const questionIndex = questionData.title;

        // If the json_data is not in allData yet.
        if (this.allData[questionIndex] === undefined) {
            if (questionData.answerMultipleTimes) {
                this.allData[questionIndex] = [answerData];
            }
            else {
                this.allData[questionIndex] = answerData;
            }
            // If the json_data is already in allData.
        }
        else if (questionData.answerMultipleTimes) {
            this.allData[questionIndex][this.allData[questionIndex].length] = answerData;
        }
        else {
            // Replace it.
            this.allData[questionIndex] = answerData;
        }
        this.answers.push(jsonData);
    }

    /**
     * Render the different input types to the template.
     */
    renderInput(question, answer, parentList = '') {
        let elementName = `${parentList}[${question.id}]${question.question_id ? `[${question.question_id}]` : ''}`;

        let answerValue = null;
        const self = this;
        this.answers.forEach((obj) => {
            Object.keys(obj).forEach((prop) => {
                if (elementName === prop || parentList === prop) {
                    if (question.type === 'mcq' || question.type === 'checkbox') {
                        if (answer.id === obj[prop]) {
                            answerValue = obj[prop];
                        }
                    }
                    else {
                        answerValue = obj[prop];
                    }
                }
            });
        });

        if (answerValue !== null) {
            document.querySelector('.js--next-question').removeAttribute('disabled');
        }

        const isDefined = (value) => (value !== null && value !== undefined);
        const requiredClass = (value) => (value ? 'form-label--required' : '');

        let {title, description} = answer;

        Object.keys(answer).forEach((key) => {
            /* This replaces the default title/description for each question
            ** with with a custom one, based on the salutation.
            */
            if (key.includes(`title-${self.template.salutation}`)) {
                title = answer[key];
            }
            else if (key.includes(`description-${self.template.salutation}`)) {
                description = answer[key];
            }
        });

        if (question.type === 'mcq') {
            const elem = document.createElement('div');
            elem.innerHTML = `<label class="form-label ${requiredClass(question.requiredWhenSelected || question.required)}">
            <input type="radio"
                onclick="(() => { this.parentElement.parentElement.querySelectorAll('.js--child-list').forEach(item => {item.classList.add('hidden'); item.firstElementChild.querySelector('input').removeAttribute('required'); });
                ${isDefined(answer.subQuestions) ? 'this.parentElement.querySelector(\'.js--child-list\').classList.remove(\'hidden\'); if(this.parentElement.querySelector(\'.js--child-list\').firstElementChild.className.includes(\'required\')) this.parentElement.querySelector(\'.js--child-list\').firstElementChild.querySelector(\'input\').setAttribute(\'required\', \'true\');' : ''} })()"

                name="${elementName}" value="${answer.id}" ${answerValue !== null ? 'checked' : ''} ${question.required ? 'required' : ''} />
            ${isDefined(title) ? title : ''}
            ${answer.subQuestions !== undefined ? '<div class=\'js--child-list hidden\'></div>' : ''}
            </label>
            `;

            if (elem.querySelector('.js--child-list') !== null) {
                answer.subQuestions.map(((subQuestion) => elem.querySelector('.js--child-list').appendChild(this.renderInput(answer, subQuestion, elementName))));
            }

            if (answer.click) {
                elem.firstChild.addEventListener('click', () => {
                    // eslint-disable-next-line no-eval
                    eval(answer.click)();
                });
            }

            elem.firstChild.addEventListener('click', () => {
                document.querySelector('.js--next-question').disabled = false;
            });

            return elem.firstChild;
        }

        if (question.type === 'checkbox') {
            const elem = document.createElement('div');
            elem.innerHTML = `<label class="form-label ${requiredClass(answer.required)}">
            <input type="checkbox"
                onclick="(() => {
                    this.parentElement.parentElement.querySelectorAll('.js--child-list').forEach(item => {
                        item.classList.add('hidden');
                        item.firstElementChild.querySelector('input').removeAttribute('required');
                });

            ${isDefined(answer.subQuestions) ? 'this.parentElement.querySelector(\'.js--child-list\').classList.remove(\'hidden\'); if(this.parentElement.querySelector(\'.js--child-list\').firstElementChild.className.includes(\'required\')) this.parentElement.querySelector(\'.js--child-list\').firstElementChild.querySelector(\'input\').setAttribute(\'required\', \'true\');'
        : ''}
                })()"

                name="${elementName}" value="${answer.id}" ${answerValue !== null ? 'checked' : ''} ${isDefined(answer.required) ? 'required' : ''} />

            ${isDefined(title) ? title : ''}
            ${isDefined(answer.subQuestions) ? '<div class=\'js--child-list hidden\'></div>' : ''}

            </label>
            `;

            elementName = `${parentList}[${question.question_id}][${question.id}][${answer.id}]`;

            if (elem.querySelector('.js--child-list') !== null) {
                answer.subQuestions.map(((subQuestion) => elem.querySelector('.js--child-list').appendChild(this.renderInput(answer, subQuestion, elementName))));
            }

            if (answer.click) {
                elem.firstChild.addEventListener('click', () => {
                    // eslint-disable-next-line no-eval
                    eval(answer.click)();
                });
            }

            elem.firstChild.addEventListener('click', (e) => {
                if (e.target.checked) {
                    document.querySelector('.js--next-question').disabled = false;
                }
                else {
                    document.querySelector('.js--next-question').disabled = true;
                }
            });

            return elem.firstChild;
        }

        if (question.type === 'open') {
            if (answer.textarea === true) {
                const elem = document.createElement('div');
                elem.innerHTML = `<label class="form-label ${requiredClass(answer.requiredWhenSelected || answer.required)}">
                ${isDefined(title) ? title : ''}
                <textarea class="form-control" name="${elementName}[${answer.id}]" ${answer.required ? 'required' : ''} >${answerValue != null ? answerValue : ''}</textarea>
                <div class="form-text">${isDefined(description) ? description : ''}</div></label>`;

                if (answer.click) {
                    elem.firstChild.addEventListener('click', () => {
                        // eslint-disable-next-line no-eval
                        eval(answer.click)();
                    });
                }

                elem.firstChild.addEventListener('input', () => {
                    // If the form has already been filled in once, or this form is filled in.
                    if (this.filledInForms.includes(this.template.currentQuestion)
                    || this.isFormFilledIn()) {
                        document.querySelector('.js--next-question').disabled = false;
                    }
                    else {
                        document.querySelector('.js--next-question').disabled = true;
                    }

                    // This checks if all fields has been filled to make the button clickable
                    if (this.filledInForms.includes(this.template.currentQuestion)
                    && this.isFormFilledIn()) {
                        document.querySelector('.js--add-medicine').disabled = false;
                    }
                    else {
                        document.querySelector('.js--add-medicine').disabled = true;
                    }
                });
                return elem.firstChild;
            }

            const elem = document.createElement('div');
            elem.innerHTML = `<label class="form-label ${requiredClass(answer.requiredWhenSelected || answer.required)}">
                ${isDefined(title) ? title : ''}
                <input class="form-control" type="text" name="${elementName}[${answer.id}]" ${answer.required ? 'required' : ''} value="${answerValue != null ? answerValue : ''}" />
                <div class="form-text">${isDefined(description) ? description : ''}</div></label>`;

            if (answer.click) {
                elem.firstChild.addEventListener('click', () => {
                    // eslint-disable-next-line no-eval
                    eval(answer.click)();
                });
            }

            elem.firstChild.addEventListener('input', () => {
                // If the form has already been filled in once, or this form is filled in.
                if (this.filledInForms.includes(this.template.currentQuestion)
                || this.isFormFilledIn()) {
                    document.querySelector('.js--next-question').disabled = false;
                    document.querySelector('.js--add-medicine').disabled = false;
                }
                else {
                    document.querySelector('.js--next-question').disabled = true;
                    document.querySelector('.js--add-medicine').disabled = true;
                }
            });

            return elem.firstChild;
        }

        if (question.type === 'actionButton') {
            const elem = document.createElement('div');
            elem.innerHTML = `<div>
                <button type="button" class="rse-modal-btn rse-modal-btn-light js--add-medicine" disabled="">${answer.title}</button>
                <div></div>
            </div>`;

            elem.firstChild.addEventListener('click', () => {
                // eslint-disable-next-line no-eval
                eval(question.action)();

                this.filledInForms.push(this.template.currentQuestion - 1);

                document.querySelector('.js--next-question').disabled = false;

                this.removeRequiredAttributes();
            });

            return elem.firstChild;
        }
        return false;
    }

    /**
     * Render the question bulletins at the top of the template.
     */
    renderQuestionBulletins() {
        const bulletins = this.template.modal.querySelector('.rse-modal-question-bulletins');

        // Empty bullet list.
        while (bulletins.firstChild) {
            bulletins.lastChild.remove();
        }

        this.questionData.forEach((element) => {
            const bullet = document.createElement('li');

            if (element.id < this.template.currentQuestion) {
                bullet.classList.add('completed');
            }
            else if (element.id === this.template.currentQuestion) {
                bullet.classList.add('active');
            }

            bulletins.appendChild(bullet);
        });
    }

    showMessageUnderForm(message) {
        const form = this.template.modal.querySelector('form');

        const brNode = document.createElement('br');

        const divNode = document.createElement('div');
        divNode.appendChild(document.createTextNode(message));

        form.appendChild(brNode);
        form.appendChild(divNode);
    }

    /*
    Checks if the required fields on the form are filled in.

    Returns:
    True if the required fields on the form are filled in, false otherwise.
    */
    isFormFilledIn() {
        const form = this.template.modal.querySelector('form');

        let inputs = Array.prototype.slice.call(form.getElementsByTagName('input'), 0);
        let textAreas = Array.prototype.slice.call(form.getElementsByTagName('textarea'), 0);

        inputs = inputs.filter((i) => i.required && !i.value);
        textAreas = textAreas.filter((tA) => tA.required && !tA.value);

        return inputs.length === 0 && textAreas.length === 0;
    }

    /*
    Checks if the form has any required fields.

    Returns:
    True if the form has any required fields, false otherwise.
    */
    doesFormHaveRequiredFields() {
        const form = this.template.modal.querySelector('form');

        let inputs = Array.prototype.slice.call(form.getElementsByTagName('input'), 0);
        let textAreas = Array.prototype.slice.call(form.getElementsByTagName('textarea'), 0);

        inputs = inputs.filter((i) => i.required);
        textAreas = textAreas.filter((tA) => tA.required);

        return inputs.length > 0 || textAreas.length > 0;
    }

    removeRequiredAttributes() {
        const form = this.template.modal.querySelector('form');

        const inputs = Array.prototype.slice.call(form.getElementsByTagName('input'), 0);
        const textAreas = Array.prototype.slice.call(form.getElementsByTagName('textarea'), 0);

        inputs.forEach((i) => i.removeAttribute('required'));
        textAreas.forEach((tA) => tA.removeAttribute('required'));
    }
}

window.Bijwerkingentool = Bijwerkingentool;
